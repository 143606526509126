export interface ITenantConfig {
  featuresConfig: FeaturesConfig;
  readerConfig: ReaderConfig;
  selectionAlgorithmNotebookUrl: string;
}

export interface IPublicTenantConfig {
  bannerConfiguration: BannerConfig;
}

export type BrandingDetails = {
  primaryColor?: string;
  secondaryColor?: string;
};

export type FeaturesConfig = {
  HSFUniversity: boolean;
  coachMatch: boolean;
  conferenceMentorMatch: boolean;
  discernmentSurvey: boolean;
  mentorMatch: boolean;
  realTimeChat: boolean;
  financialPortal: boolean;
};

type ReaderConfig = {
  maxReadsByReader: number;
  requiredReads: number;
  diverseEthnicity: boolean;
};

export type BannerConfig = {
  isBannerEnabled: boolean;
  title?: string;
  description?: string;
};

export enum TenantBranding {
  TENANT = 'tenant',
  GENERIC = 'generic',
}

export enum FundsDisbursement {
  TO_UNIVERSITIES = 'university',
  TO_STUDENTS_DIRECTLY = 'student',
}

export enum Feature {
  MENTOR_MATCH = 'mentorMatch',
  COACH_MATCH = 'coachMatch',
  CONFERENCE_MENTOR_MATCH = 'conferenceMentorMatch',
  DISCERNMENT_SURVEY = 'discernmentSurvey',
  HSF_UNIVERSITY = 'HSFUniversity',
  REAL_TIME_CHAT = 'realTimeChat',
  FINANCIAL_PORTAL = 'financialPortal',
}
