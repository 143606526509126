import { useSelector } from 'react-redux';

import { isAdmin } from './auth';

import { Feature, FeaturesConfig, ITenantConfig } from '../../types/models/tenant-management';
import { RootState } from '../reducers';

export const getTenant = (state: RootState) => state.tenant;
export const getTenantConfig = (state: RootState) => getTenant(state)?.tenantConfig || ({} as ITenantConfig);

export const isFeatureEnabled = (feature: Feature) => {
  return (state: RootState) => {
    const { featuresConfig }: ITenantConfig = getTenantConfig(state);
    if (!featuresConfig) {
      return false;
    }

    return featuresConfig[feature];
  };
};

export const isMentorMatchEnabled = () => {
  return isFeatureEnabled(Feature.MENTOR_MATCH);
};

export const isCoachMatchEnabled = () => {
  return isFeatureEnabled(Feature.COACH_MATCH);
};

export const isConferenceMentorMatchEnabled = () => {
  return isFeatureEnabled(Feature.CONFERENCE_MENTOR_MATCH);
};

export const isDiscernmentSurveyEnabled = () => {
  return isFeatureEnabled(Feature.DISCERNMENT_SURVEY);
};

export const isHSFUniversityEnabled = () => {
  return isFeatureEnabled(Feature.HSF_UNIVERSITY);
};

export const isRealTimeChatEnabled = () => {
  return (state: RootState) => {
    return isFeatureEnabled(Feature.REAL_TIME_CHAT)(state) && !isAdmin(state);
  };
};

export const isFinancialPortalEnabled = () => {
  return (state: RootState) => {
    return isFeatureEnabled(Feature.FINANCIAL_PORTAL)(state);
  };
};

export const useFeatures = (): FeaturesConfig => {
  const { featuresConfig }: ITenantConfig = useSelector(getTenantConfig);
  if (!featuresConfig || Object.keys(featuresConfig).length === 0) {
    return {
      mentorMatch: false,
      coachMatch: false,
      conferenceMentorMatch: false,
      discernmentSurvey: false,
      HSFUniversity: false,
      realTimeChat: false,
      financialPortal: false,
    };
  }

  return {
    mentorMatch: featuresConfig[Feature.MENTOR_MATCH],
    coachMatch: featuresConfig[Feature.COACH_MATCH],
    conferenceMentorMatch: featuresConfig[Feature.CONFERENCE_MENTOR_MATCH],
    discernmentSurvey: featuresConfig[Feature.DISCERNMENT_SURVEY],
    HSFUniversity: featuresConfig[Feature.HSF_UNIVERSITY],
    realTimeChat: featuresConfig[Feature.REAL_TIME_CHAT],
    financialPortal: featuresConfig[Feature.FINANCIAL_PORTAL],
  };
};
