import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { getAuth } from '../../state/selectors/auth';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function SSOAuthContainer() {
  const form = useRef<HTMLFormElement>(null);
  const { samlResponse } = useSelector(getAuth);

  useEffect(() => {
    if (samlResponse && form?.current) {
      form.current.submit();
    }
  }, [form, samlResponse]);

  if (!samlResponse) {
    return null;
  }

  return (
    <Container>
      <form ref={form} method="post" action={samlResponse.entityEndpoint} autoComplete="off">
        <input type="hidden" name="SAMLResponse" id="SAMLResponse" value={samlResponse.context} />
      </form>
    </Container>
  );
}
